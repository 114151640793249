import React from "react";

import "./LinkList.css";

const LinkList = (props) => {
  console.log(props);
  let actionProvider = props.actionProvider;
  let options = null;
  if (props.payload) {
    options = props.payload;
  }
  else options = props.options;
  const handleOptionClick = (handler) => {
    if (actionProvider && typeof handler === 'function') {
      handler(actionProvider);
    } else {
      console.error("ActionProvider is undefined or handler is not a function");
    }
  };

  const linkMarkup = options.map((link) => (
    link.url ? (
      <li key={link.id} className="link-list-item">
        <a
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="learning-option-button"
        >
          {link.text}
        </a>
      </li>
    ) : (
      <li key={link.id} className="link-list-item">
        <button 
          key={link.id}
          onClick={() => handleOptionClick(link.handler)}
          className="learning-option-button"
        >
          {link.text}
        </button>
      </li>
    )
  ));
  console.log("THIS IS THE" + linkMarkup)
  return <ul className="link-list">{linkMarkup}</ul>;
};

export default LinkList;
