import { useEffect } from "react";
import React from "react";
import "./LearningOptions.css";


const LearningOptions = (props) => {
  let { actionProvider, options, payload} = props;
  if( payload){
    console.log("payload is:", payload)
    options = payload
  }
  console.log("ActionProvider:", actionProvider);  // Check if actionProvider is undefined
  console.log("Options Data in ActionProvider is:", options)
  console.log("props are:", props)
  useEffect(() => {
    console.log("Options updated:", options);
  }, [options]); // Only re-run the effect if options changes
  const handleOptionClick = (handler) => {
    if (actionProvider && typeof handler === 'function') {
      handler(actionProvider);
    } else {
      console.error("ActionProvider is undefined or handler is not a function");
    }
  };

  return (
    <div className="learning-options-container">
      {options.map(option => (
        <button
          key={option.id}
          onClick={() => handleOptionClick(option.handler)}
          className="learning-option-button"
        >
          {option.text}
        </button>
      ))}
    </div>
  );
};

export default LearningOptions;
// const LearningOptions = (props) => {
//   const options = [
//     {
//       text: "Javascript",
//       handler: props.actionProvider.handleJavascriptList,
//       id: 1,
//     },
//     { text: "Data visualization", handler: () => {}, id: 2 },
//     { text: "APIs", handler: () => {}, id: 3 },
//     { text: "Security", handler: () => {}, id: 4 },
//     { text: "Interview prep", handler: () => {}, id: 5 },
//   ];

//   const optionsMarkup = options.map((option) => (
//     <button
//       className="learning-option-button"
//       key={option.id}
//       onClick={option.handler}
//     >
//       {option.text}
//     </button>
//   ));

//   return <div className="learning-options-container">{optionsMarkup}</div>;
// };

// export default LearningOptions;
