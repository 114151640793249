
import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { ReactTyped } from "react-typed";

function TextComponent(props) {
    const [data, setData] = useState(null);  // Now initialized as null

    useEffect(() => {
        const fetchCSV = async () => {
            try {
                const response = await fetch('./minified_escaped_chats.csv');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const text = await response.text();

                Papa.parse(text, {
                    header: true,
                    skipEmptyLines: true,
                    delimiter: ',',  
                    quoteChar: '"',  
                    escapeChar: '"',
                    complete: (results) => {
                        console.log('results are:', results)
                        if (results.data.length > 0) {
                            console.log('props inside textcomponent are: ', props, props.payload.id)
                            const dataElement = results.data.find((item) => item.id == props.payload.id);
                            console.log('data element is:', dataElement);
                            setData(dataElement);
                        } else {
                            setData(null);  // Handle empty results
                        }
                    }
                });
            } catch (error) {
                console.error('Failed to fetch the file:', error);
                setData(null);
            }
        };

        fetchCSV();
    }, [props.payload.id]);  // Depend on props.payload.id to re-fetch if id changes

    console.log('data is:', data)

    if (!data) {
        return null;  // Returns nothing until the data is ready
    }

    const htmlData = data ? data.Answer : 'No data found for this ID';
    return (
        <div>
        <div className="react-chatbot-kit-chat-bot-message-container">
          {/* <img className="App-logo" src="finleymain.jpeg" alt="logo" /> */}
          <div
            className="react-chatbot-kit-chat-bot-message"
          >
            <ReactTyped strings={[htmlData]} typeSpeed={2} showCursor={false} backDelay={1000} startDelay={2000} backSpeed={30}
            onComplete={() => props.actionProvider.handleNestedOptions(props.payload.id, props.payload.nextOptionsKeys, 'handleNestedOptions')}
            />
          </div>
        </div>
        {/* {showLinks && <LinkList {...props} />} */}
        {/* {showLinks && <LearningOptions {...actionprops} />} */}
      </div>
      );
}

export default TextComponent;
