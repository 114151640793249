import {
  createCustomMessage,
} from 'react-chatbot-kit';


const dropdownOptions = [
  {
    text: "What are my affordable mechanization options in Bangladesh?",
    handler: props => props.handleNestedOptions(1, [6, 7, 8]),
    id: 1,
  },
  {
    text: "What’re the benefits of mechanization in Bangladesh?",
    handler: props => props.handleNestedOptions(3, [14, 15, 16]),
    id: 3,
  },
  {
    text: "What agricultural products are in high demand in Bangladesh?",
    handler: props => props.handleNestedOptions(4, [17, 18, 19]),
    id: 4,
  },
  {
    text: "How can I improve digital financial literacy for me and my wife?",
    handler: props => props.handleNestedOptions(5, [20, 21,  22]),
    id: 5,
  },
  {
    text: "what are the challenges faced by small farmers in bangladesh in accessing agricultural machinery",
    handler: props => props.handleNestedOptions(6, []),
    id: 6,
  },
  {
    text: "what are the most affordable types of agricultural machinery available in bangladesh",
    handler: props => props.handleNestedOptions(7, []),
    id: 7,
  },
  {
    text: "what are the alternatives to purchasing agricultural machinery for small farmers in bangladesh",
    handler: props => props.handleNestedOptions(8, []),
    id: 8,
  },
  {
    text: "what are the most common types of agricultural machinery used by small farmers in bangladesh",
    handler: props => props.handleNestedOptions(9, []),
    id: 9,
  },
  {
    text: "what are the most effective ways to train small farmers in bangladesh to use agricultural machinery",
    handler: props => props.handleNestedOptions(10, []),
    id: 10,
  },
  {
    text: "what are the features of power tillers that make them affordable for small farmers in bangladesh",
    handler: props => props.handleNestedOptions(11, []),
    id: 11,
  },
  {
    text: "what are the most popular brands of agricultural machinery in bangladesh",
    handler: props => props.handleNestedOptions(12, []),
    id: 12,
  },
  {
    text: "what are the maintenance costs associated with affordable agricultural machinery in bangladesh",
    handler: props => props.handleNestedOptions(13, []),
    id: 13,
  },
  {
    text: "what are the challenges faced by the government in promoting mechanization in Bangladesh?",
    handler: props => props.handleNestedOptions(14, []),
    id: 14,
  },
  {
    text: "what are the environmental benefits of mechanization in Bangladesh?",
    handler: props => props.handleNestedOptions(15, []),
    id: 15,
  },
  {
    text: "what are the economic benefits of mechanization in Bangladesh?",
    handler: props => props.handleNestedOptions(16, []),
    id: 16,
  },
  {
    text: "what is the demand for organic agricultural products in Bangladesh?",
    handler: props => props.handleNestedOptions(17, []),
    id: 17,
  },
  {
    text: "what are the challenges faced by farmers in Bangladesh in meeting the demand for agricultural products?",
    handler: props => props.handleNestedOptions(18, []),
    id: 18,
  },
  {
    text: "what are the challenges faced by farmers in Bangladesh in accessing credit?",
    handler: props => props.handleNestedOptions(19, []),
    id: 19,
  },
  {
    text: "what are some online courses or programs that can improve digital financial literacy in Bangladesh?",
    handler: props => props.handleNestedOptions(20, []),
    id: 20,
  },
  {
    text: "what are some free resources available to improve financial literacy in Bangladesh?",
    handler: props => props.handleNestedOptions(21, []),
    id: 21,
  },
  {
    text: "how can employers in Bangladesh help improve financial literacy among their employees?",
    handler: props => props.handleNestedOptions(22, []),
    id: 22,
  },
  {
    text: "How can I and my wife improve our chances of getting a loan to buy agricultural machinery?",
    handler: props => props.handleNestedOptions(23, [24, 25, 26]),
    id: 23,
  },
  {
    text: "what are the requirements for getting a loan for agricultural machinery in Bangladesh?",
    handler: props => props.handleNestedOptions(24, []),
    id: 24,
  },
  {
    text: "what are the interest rates for agricultural loans in Bangladesh?",
    handler: props => props.handleNestedOptions(25, []),
    id: 25,
  },
  {
    text: "what are the repayment terms for agricultural loans in Bangladesh?",
    handler: props => props.handleNestedOptions(26, []),
    id: 26,
  }
];

const initialOptions = [
  {
    text: "What are my affordable mechanization options in Bangladesh?",
    handler: props => props.handleNestedOptions(1, [6, 7, 8]),
    id: 1,
  },
  {
    text: "What’re the benefits of mechanization in Bangladesh?",
    handler: props => props.handleNestedOptions(3, [14, 15, 16]),
    id: 3,
  },
  {
    text: "What agricultural products are in high demand in Bangladesh?",
    handler: props => props.handleNestedOptions(4, [17, 18, 19]),
    id: 4,
  },
  {
    text: "How can I improve digital financial literacy for me and my wife?",
    handler: props => props.handleNestedOptions(5, [20, 21,  22]),
    id: 5,
  },
  {
    text: "How can I and my wife improve our chances of getting a loan to buy agricultural machinery?",
    handler: props => props.handleNestedOptions(23, [24, 25, 26]),
    id: 23,
  }
];

const exitOptions = [
  {
    text: "Check out CIMMYT",
    url: 'https://www.cimmyt.org/',
    id: 5,
  },
  {
    text: "Reset Chat",
    handler: props => props.resetChat(),
    id: 6,
  }
]

const optionsData = {
  initialOptions,
};

class ActionProvider {
  constructor(createChatBotMessage, setStateFunc, createClientMessage) {
    this.createChatBotMessage = createChatBotMessage;
    this.createCustomMessage = createCustomMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
    this.optionsData = optionsData;
    this.dropdownOptions = dropdownOptions;
  }

  // new method
  greet() {
    const greetingMessage = this.createChatBotMessage("Hi, friend.");
    this.updateChatbotState(greetingMessage);
  }
  handleNestedOptions = (id, nextOptionsKeys, action = null) => {
    if (!action){
      if (!nextOptionsKeys){
        this.handleExitOptions();
        return;
      }
      console.log("Handling nested options:", nextOptionsKeys);  // Debug log
      console.log('dropdown options: ', this.dropdownOptions)
      const nextOptions = this.dropdownOptions.filter((option) => nextOptionsKeys.includes(option.id));
      console.log("Next Options are: ", nextOptions)
      console.log('id is:', id)
      const thisOption = this.dropdownOptions.find((option) => option.id === id);
      const message = this.createCustomMessage(thisOption.text, 'user', {
        delay: 0
      });
      this.updateChatbotState(message);
      const message0 = this.createCustomMessage('Sure, give me a second...', 'bot', {
        widget: 'textComponent',
        payload: {id, nextOptionsKeys},
        delay: 500,
      })
      this.updateChatbotState(message0)
      if (!nextOptions){
        this.handleExitOptions();
        return;
      }
    }
    else {
      console.log("Handling nested options:", nextOptionsKeys);  // Debug log
      console.log('dropdown options: ', this.dropdownOptions)
      const nextOptions = this.dropdownOptions.filter((option) => nextOptionsKeys.includes(option.id));
      if (!nextOptions || nextOptionsKeys.length == 0){
        this.handleExitOptions();
        return;
      }
      const message = this.createCustomMessage(
        'Would you like to ask anything else?', 'bot', {
          widget: 'learningOptions',
          payload: nextOptions
        });
        this.updateChatbotState(message);

    }
  };

  handleExitOptions = () => {
    const message = this.createCustomMessage(
      "That's all we offer in the demo version!", 'bot',
      {
        widget: "javascriptLinks",
        payload: exitOptions
      }
    );

    this.updateChatbotState(message);
  };

  handleOptionSelected = (message) => {
    const chatMessage = this.createChatBotMessage(message);
    this.updateChatbotState(chatMessage);
  };


  handleJavascriptList = () => {
    const message = this.createChatBotMessage(
      "Fantastic, I've got the following resources for you on Javascript:",
      {
        widget: "javascriptLinks",
      }
    );

    this.updateChatbotState(message);
  };

  resetChat() {
    this.setState((prevState) => ({
      ...prevState,
      messages: [prevState.messages[0]],
    }));
  }

  updateChatbotState(message) {
    // NOTICE: This function is set in the constructor, and is passed in from the top level Chatbot component. The setState function here actually manipulates the top level state of the Chatbot, so it's important that we make sure that we preserve the previous state.
    console.log("message is:", message)
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  }
}

export default ActionProvider;
