import React from "react";
import { createChatBotMessage,   createCustomMessage } from "react-chatbot-kit";

import LearningOptions from "../components/LearningOptions/LearningOptions";
import LinkList from "../components/LinkList/LinkList";
import CustomMessage from "../components/CustomMessage/CustomMessage";
import TextComponent from "../components/TextComponent/TextComponent";
const initialOptions = [
  {
    text: "What are my affordable mechanization options in Bangladesh?",
    handler: props => props.handleNestedOptions(1, [6, 7, 8]),
    id: 1,
  },
  {
    text: "What’re the benefits of mechanization in Bangladesh?",
    handler: props => props.handleNestedOptions(3, [14, 15, 16]),
    id: 3,
  },
  {
    text: "What agricultural products are in high demand in Bangladesh?",
    handler: props => props.handleNestedOptions(4, [17, 18, 19]),
    id: 4,
  },
  {
    text: "How can I improve digital financial literacy for me and my wife?",
    handler: props => props.handleNestedOptions(5, [20, 21,  22]),
    id: 5,
  },
  {
    text: "How can I and my wife improve our chances of getting a loan to buy agricultural machinery?",
    handler: props => props.handleNestedOptions(23, [24, 25, 26]),
    id: 23,
  }
];

const frontendOptions = [
  {
    text: "React Basics",
    handler: props => props.actionProvider.handleOptionSelected("React is a popular JS library."),
    id: 3,
  },
  {
    text: "Vue Introduction",
    handler: props => props.actionProvider.handleOptionSelected("Vue is progressive JS framework."),
    id: 4,
  }
];

const backendOptions = [
  {
    text: "Node.js",
    handler: props => props.actionProvider.handleOptionSelected("Node.js is a JS runtime environment."),
    id: 5,
  },
  {
    text: "Django",
    handler: props => props.actionProvider.handleOptionSelected("Django is a high-level Python web framework."),
    id: 6,
  }
];

const optionsData = {
  initialOptions,
  frontendOptions,
  backendOptions
};
const config = {
  botName: "CIMMYT Bot",
  initialMessages: [
    createChatBotMessage("Hi, I'm here to help. What would you like to ask?", {
      widget: "learningOptions",
    }),
    createCustomMessage('Test', 'custom'),
  ],
  customComponents: {
    // Register your custom component under a unique name
    custom: (props) => <CustomMessage {...props} />,
    textComponent: (props) => <TextComponent {...props} />,
    LearningOptions: (props) => <LearningOptions {...props} />,
    botAvatar: () => <img className = "App-logo" src="logo.png" alt="logo" />,
  },
  state: {
    optionsData,
    initialOptions
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: "#2898ec",
    },
    chatButton: {
      backgroundColor: "#2898ec",
    },
  },
  widgets: [
    {
      widgetName: "learningOptions",
      widgetFunc: (props) => <LearningOptions {...props} actionProvider={props.actionProvider}/>,
      props: { options: optionsData.initialOptions },
      mapStateToProps: ["optionsData", "initialOptionsData"], 
    },
    {
      widgetName: "textComponent",
      widgetFunc: (props) => <TextComponent {...props} actionProvider={props.actionProvider}/>,
      props: { id: 0 },
    },
    {
      widgetName: "javascriptLinks",
      widgetFunc: (props) => <LinkList {...props} />,
      props: {
        options: [
          {
            text: "Introduction to JS",
            url:
              "https://www.freecodecamp.org/learn/javascript-algorithms-and-data-structures/basic-javascript/",
            id: 1,
          },
          {
            text: "Mozilla JS Guide",
            url:
              "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide",
            id: 2,
          },
          {
            text: "Frontend Masters",
            url: "https://frontendmasters.com",
            id: 3,
          },
        ],
      },
    },
  ],
};

export default config;
