import React from 'react';

const CustomMessage = () => {
  return (
    <img
      src='https://i.pinimg.com/originals/cf/da/fa/cfdafa4dc6aab40eae1c5315c02b9339.jpg'
      alt = {""}
      style={{ width: '100%' }}
    />
  );
};

export default CustomMessage;